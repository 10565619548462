import React from "react";
import Content from "./style";
export default function ImgMediaCard(Props) {
  const { texto, icono, titulo } = Props;
  return (
    <Content.CardContainer>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Content.Icon>
          <i className={icono} />
        </Content.Icon>
        <h4
          style={{
            fontSize: "21px",
            fontWeight: 500,
            letterSpacing: "normal",
            lineHeight: "30px",
          }}
        >
          {titulo}
        </h4>
        <p>{texto}</p>
      </div>
    </Content.CardContainer>
  );
}
