import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Content from "./style";
import ImgMediaCard from "./Card";
export default function ContentSectionOne() {
  return (
    <Content backgroundColor="#fff">
      <Container
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "3rem",
        }}
      >
          <Content.Title>Gestiona tu inventario</Content.Title>
        <Content.Box>
          <Row className=" justify-content-center">
            <Col style={{ marginBottom: "20px" }} xs={12} md={4}>
              <ImgMediaCard
                texto="Explora y gestiona eficientemente tu inventario visualizándolo en una tabla detallada, que proporciona una visión clara y completa de todos los artículos actualmente empeñados. "
                icono="fa fa-table"
                titulo="Con una tabla"
              />
            </Col>
            <Col style={{ marginBottom: "20px" }} xs={12} md={4}>
              <ImgMediaCard
                texto="Explora y gestiona eficientemente tu inventario visualizándolo en una tabla detallada, que proporciona una visión clara y completa de todos los artículos actualmente empeñados. "
                icono="fa fa-table"
                titulo="Con una tabla"
              />
            </Col>
            <Col style={{ marginBottom: "20px" }} xs={12} md={4}>
              <ImgMediaCard
                texto="Explora y gestiona eficientemente tu inventario visualizándolo en una tabla detallada, que proporciona una visión clara y completa de todos los artículos actualmente empeñados. "
                icono="fa fa-table"
                titulo="Con una tabla"
              />
            </Col>
          </Row>
        </Content.Box>
      </Container>
    </Content>
  );
}
