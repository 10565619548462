import styled from "styled-components/macro";
import { Box, Heading, Button, Paragraph } from "~styled";

const Content = styled(Box)`
  @media (max-width: 2000px) {
    height: 85vh;
  }
  @media (max-width: 1200px) {
    height: 100vh;
  }
  @media (max-width: 768px) {
    height: 150vh;
  }
  @media (max-width: 480px) {
    height: 155vh;
  }
`;

Content.ImageGroup = styled(Box)`
  position: relative;
  z-index: 1;
  width: 80%;
  margin: 0 auto;
`;

Content.ImageShape = styled(Heading)`
    position: absolute;
    top: 50%;
    left: 56%;
    z-index: -1;
    transform: translate(-50%, -50%);
    width: 90%;
    img{
    width: 100%;
    }
    }
    @media (min-width: 992px) {
    margin-left: 30px;
    }
`;
Content.Title = styled(Paragraph)`
  font-size: 32px;
  font-weight: 500;
  letter-spacing: normal;
  line-height: 1.3;
  @media (min-width: 768px) {
    font-size: 38px;
  }
  @media (min-width: 992px) {
    font-size: 48px;
  }
  text-align: center;
  color: #000;
`;
Content.Box = styled(Box)``;
Content.Icon = styled(Box)`
  margin: 0 0;
  margin-bottom: 32px;
  font-size: 20px;
  min-width: 50px;
  max-width: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #057d7b;
  color: #fff;
`;
Content.CardContainer = styled('div')`
  box-shadow: 1px 1px 20px #f1f1f1;
  opacity: 0;
  transition: all 0.3s;
  opacity: 1;
  min-height: 300px;
  padding: 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &:hover {
    background-color: rgba(80, 52, 252, 0.9);
    color: #fff;
    h4 {
      color: #fff;
    }
    .cardIcon{
      background-color: #fff;
      color: rgba(80, 52, 252, 0.9);
    }
  }
`;
Content.Icon = styled(Box).attrs({ className: "cardIcon" })`
  margin: 0;
  font-size: 20px;
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(80, 52, 252, 0.9);
  color: #fff;
  width: 50px;
`;
export default Content;
